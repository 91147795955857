<template>
  <div v-if="dataResponse != null">
    <b-card-text v-if="dataResponse['currencyId'] === null" class="mb-0">
      {{ $t('Amount') }} : {{ numberWithCommas(dataResponse['amount']) }}
    </b-card-text>
    <b-card-text v-if="dataResponse['currencyId'] !== null" class="mb-0">
      {{ $t('Amount') }} : {{ numberWithCommas((dataResponse['amount_currency']).toFixed(2)) }} {{ dataResponse['currencySymbol'] }}
    </b-card-text>
    <b-card-text class="mb-0">
      {{ $t('Slip') }}
    </b-card-text>
    <b-img
      v-if="dataResponse['slip']"
      thumbnail
      fluid
      :src="dataResponse['slip']"
      :alt="dataResponse['_id']"
    />
    <b-img
      v-else
      :src="require('@/assets/images/blank/no_image.png')"
      fluid
      thumbnail
      width="100px"
      :alt="dataResponse['_id']"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
