<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="text-center mt-2 mb-0">
          <b-badge
            pill
            variant="primary"
            class="badge-glow"
          >
            {{ $t('Payment Status') }}
          </b-badge>
        </div>
        <b-tabs
          v-if="countData"
          class="mt-2 mb-2"
          pills
          :nav-class="paymentStatusPills()"
          align="center"
        >
          <b-tab
            :active="paymentStatus === 'all' ? true : false"
            :title="$t('All') + ' (' + countData['all'] + ')'"
            @click="paymentStatusChange('all')"
          />
          <b-tab
            :active="paymentStatus === 'create' ? true : false"
            :title="$t('Create') + ' (' + countData['create'] + ')'"
            @click="paymentStatusChange('create')"
          />
          <b-tab
            :active="paymentStatus === 'pending' ? true : false"
            :title="$t('Pending') + ' (' + countData['pending'] + ')'"
            @click="paymentStatusChange('pending')"
          />
          <b-tab
            :active="paymentStatus === 'success' ? true : false"
            :title="$t('Success') + ' (' + countData['success'] + ')'"
            @click="paymentStatusChange('success')"
          />
          <b-tab
            :active="paymentStatus === 'cancel' ? true : false"
            :title="$t('Cancel') + ' (' + countData['cancel'] + ')'"
            @click="paymentStatusChange('cancel')"
          />
          <b-tab
            :active="paymentStatus === 'delete' ? true : false"
            :title="$t('Delete') + ' (' + countData['delete'] + ')'"
            @click="paymentStatusChange('delete')"
          />
          <b-tab
            :active="paymentStatus === 'error' ? true : false"
            :title="$t('Error') + ' (' + countData['error'] + ')'"
            @click="paymentStatusChange('error')"
          />
        </b-tabs>
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t('entries') }}</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <template #cell(confirm)="data">
            <b-button
              v-if="
                data.item.paymentStatus !== 'success' &&
                  data.item.paymentStatus !== 'cancel' &&
                  data.item.paymentStatus !== 'delete' &&
                  data.item.paymentStatus !== 'error'
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              block
              @click="modalEdit(data.item)"
            >
              <span class="mr-25 align-middle"> {{ $t('Confirm') }}</span>
              <feather-icon
                icon="ShoppingBagIcon"
                size="12"
              />
            </b-button>
          </template>
          <template #cell(_id)="data">
            <b-card-text>{{
              subStringOrderId(data.item._id).toUpperCase()
            }}</b-card-text>
          </template>

          <template #cell(paymentStatus)="data">
            <b-badge
              pill
              :variant="paymentStatusColor(data.item.paymentStatus)"
              class="badge-glow"
            >
              {{ data.item.paymentStatus | firstStringUpper }}
            </b-badge>
          </template>
          <template #cell(fullName)="data">
            <b-card-text>{{ data.item.userInfo.firstName }}
              {{ data.item.userInfo.lastName }}</b-card-text>
          </template>
          <template #cell(amount)="data">
            <b-row>
              <b-card-text v-if="data.item.currencyId == null">{{
                numberWithCommas(data.item.amount)
              }}</b-card-text>
              <b-card-text
                v-else
              >{{
                numberWithCommas(
                  (data.item.amount_currency / 100).toFixed(2),
                )
              }}{{ data.item.currencySymbol }}</b-card-text>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="modalEdit(data.item)">
                <feather-icon icon="MenuIcon" />
                <span class="align-middle ml-50">{{ $t('Detail') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/creditConfirmPayment'
import debounce from 'debounce'
import Modal from './Modal.vue'

const STORE_MODULE_NAME = 'creditConfirmPayment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BTabs,
    BTab,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      paymentStatus: localStorage.getItem('paymentStatus')
        ? localStorage.getItem('paymentStatus')
        : 'all',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      isModalActive: false,
      sidebarData: {},
      modalData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'confirm', label: this.$t('Confirm'), sortable: false },
        { key: '_id', label: this.$t('OrderId'), sortable: false },

        // { key: 'paymentMethod', label: this.$t('Payment Method'), sortable: false },
        {
          key: 'paymentStatus',
          label: this.$t('Payment Status'),
          sortable: false,
        },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
        { key: 'userId.username', label: this.$t('Username'), sortable: false },
        { key: 'fullName', label: this.$t('FullName'), sortable: false },
        // { key: 'address', label: this.$t('Address'), sortable: false },
        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    countData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.count
        : null
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
      paymentStatus: this.paymentStatus,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    paymentStatusChange(data) {
      localStorage.setItem('paymentStatus', data)
      this.paymentStatus = data
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        paymentStatus: this.paymentStatus,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    paymentStatusPills() {
      if (this.paymentStatus === 'all') {
        return 'nav-pill-primary'
      }
      if (this.paymentStatus === 'create') {
        return 'nav-pill-primary'
      }
      if (this.paymentStatus === 'pending') {
        return 'nav-pill-warning'
      }
      if (this.paymentStatus === 'success') {
        return 'nav-pill-success'
      }
      if (this.paymentStatus === 'cancel') {
        return 'nav-pill-danger'
      }
      if (this.paymentStatus === 'delete') {
        return 'nav-pill-danger'
      }
      if (this.paymentStatus === 'error') {
        return 'nav-pill-danger'
      }
      return 'nav-pill-dark'
    },
    paymentStatusColor(data) {
      if (data === 'create') {
        return 'primary'
      }
      if (data === 'pending') {
        return 'warning'
      }
      if (data === 'success') {
        return 'success'
      }
      if (data === 'cancel') {
        return 'danger'
      }
      if (data === 'delete') {
        return 'danger'
      }
      if (data === 'error') {
        return 'danger'
      }
      return 'dark'
    },
    modalEdit(item) {
      this.modalData = item
      this.isModalActive = true
    },
    subStringOrderId(data) {
      return data.substr(16, data.length)
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang]
      }
      return ''
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        paymentStatus: this.paymentStatus,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        paymentStatus: this.paymentStatus,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        paymentStatus: this.paymentStatus,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
